<template>
  <template v-if="auth.loading">
    <BaseSpinner />
  </template>
  <template v-else>
    <BaseHeader :user="auth.user" />
    <main class="main overflow-hidden position-relative">
      <div class="sidebar position-absolute top-0 start-0 bottom-0 border-end">
        <BaseFilter />
      </div>

      <div class="content">
        <slot />
      </div>
    </main>
  </template>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import BaseSpinner from "@/components/BaseSpinner.vue";
import BaseHeader from "@/components/BaseHeader.vue";
import BaseFilter from "@/components/BaseFilter.vue";
import { usePusher } from "@/composables/usePusher";
import { dataTest } from "@/services";

usePusher();
const store = useStore();
const auth = computed(() => {
  return store.state.auth;
});

onMounted(() => {
  setTimeout(async () => {
    await dataTest();
  }, 1000);
});
</script>
