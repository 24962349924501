<template>
  <header class="header position-fixed top-0 start-0 end-0 z-1">
    <div class="container-fluid">
      <div class="d-flex justify-content-between align-items-center">
        <a class="navbar-brand text-white-50" href="/">
          <img
            src="https://getbootstrap.com/docs/5.0/assets/brand/bootstrap-logo.svg"
            alt=""
            width="30"
            height="24"
            class="d-inline-block align-text-top"
          />

          asx-poc-web
        </a>
        <h5 class="mb-0 text-white-50">Volatility</h5>
        <div class="d-flex align-items-center">
          <img
            src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
            class="rounded-circle"
            style="width: 30px"
            alt="Avatar"
          />
          <span class="text-white-50 text-lowercase">{{ user }}</span>

          <button class="btn" @click="handleLogout">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-door-closed-fill text-white-50"
              viewBox="0 0 16 16"
            >
              <path
                d="M12 1a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2a1 1 0 0 1 1-1zm-2 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { defineProps } from "vue";
import { useStore } from "vuex";

defineProps({
  user: String,
});

const store = useStore();

function handleLogout() {
  store.dispatch("auth/handleLogout");
}
</script>
