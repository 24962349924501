import { computed } from "vue";
import { useStore } from "vuex";
import Pusher from "pusher-js/with-encryption";

export function usePusher() {
  const store = useStore();
  const auth = computed(() => {
    return store.state.auth;
  });
  const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    channelAuthorization: {
      endpoint: `${process.env.VUE_APP_API_URI}/pusher/auth`,
      headers: {
        "x-api-key": process.env.VUE_APP_API_KEY,
        "x-csrf-token": localStorage.getItem("asx_api_access_token"),
      },
    },
  });
  const channel = pusher.subscribe("private-encrypted-blocktrade");
  channel.bind("blocktrade", (data) => {
    auth.value.user = data.data.user;
    auth.value.loading = false;
    //alert(JSON.stringify(data));
  });
}
