import { authLogin, authToken } from "@/services";

export default {
  namespaced: true,
  state: {
    loading: true,
    user: null,
    mode: "Chart",
    data: [
      {
        datetime: "9/24/2024 4:40:40 PM",
        asxCode: "BVZ",
        term: "Q4 24",
        yearCode: "2024",
        category: "Deltahedge",
        volatility: "64.23",
        optionality: "Call",
        strikePrice: "80.00",
        futurePrice: "46.00",
        optionPrice: "0.40",
        volume: "25.00",
      },
      {
        datetime: "9/24/2024 4:40:40 PM",
        asxCode: "BVZ",
        term: "Q4 24",
        yearCode: "2024",
        category: "Deltahedge",
        volatility: "67.87",
        optionality: "Put",
        strikePrice: "35.00",
        futurePrice: "46.00",
        optionPrice: "1.75",
        volume: "25.00",
      },
      {
        datetime: "9/24/2024 4:40:40 PM",
        asxCode: "BVZ",
        term: "Q4 24",
        yearCode: "2024",
        category: "Deltahedge",
        volatility: "68.13",
        optionality: "Call",
        strikePrice: "60.00",
        futurePrice: "46.00",
        optionPrice: "2.40",
        volume: "25.00",
      },
      {
        datetime: "9/24/2024 4:24:40 PM",
        asxCode: "BVZ",
        term: "Q4 24",
        yearCode: "2024",
        category: "Deltahedge",
        volatility: "64.23",
        optionality: "Call",
        strikePrice: "80.00",
        futurePrice: "46.00",
        optionPrice: "0.40",
        volume: "25.00",
      },
    ],
  },
  actions: {
    async handleLogin({ commit }) {
      try {
        commit("SET_LOADING", true);
        const response = await authLogin();
        if (response.code === 0) {
          window.location.href = response.data.url;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleToken(_, payload) {
      try {
        const response = await authToken(payload);
        if (response.code === 0) {
          localStorage.setItem(
            "asx_api_access_token",
            response.data.accessToken
          );
          localStorage.setItem(
            "asx_api_refresh_token",
            response.data.refreshToken
          );
          window.location.href = "/";
        } else {
          window.location.href = "/auth/login";
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleLogout() {
      localStorage.clear();
      window.location.href = "/auth/login";
    },
  },

  mutations: {
    SET_LOADING(state, data) {
      state.loading = data;
    },
  },
};
