<template>
  <div
    class="filter-header border-bottom d-flex justify-content-between align-items-center px-3"
  >
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-funnel"
        viewBox="0 0 16 16"
      >
        <path
          d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z"
        />
      </svg>
      <small class="ms-1"><strong>Filters</strong></small>
    </div>
    <button class="btn p-0 border-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-bar-left"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5"
        />
      </svg>
    </button>
  </div>
  <div class="filter-body px-3 py-2 overflow-auto">
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">
        <small><strong>Region</strong></small>
      </label>

      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault1"
        />
        <label class="form-check-label" for="flexRadioDefault1">
          <small>NSW</small>
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault2"
          checked
        />
        <label class="form-check-label" for="flexRadioDefault2">
          <small>QLD</small>
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault3"
          checked
        />
        <label class="form-check-label" for="flexRadioDefault3">
          <small>SA</small>
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="flexRadioDefault"
          id="flexRadioDefault4"
          checked
        />
        <label class="form-check-label" for="flexRadioDefault4">
          <small>VIC</small>
        </label>
      </div>
    </div>
    <div class="mb-3">
      <label for="exampleFormControlInput1" class="form-label">
        <small><strong>Year & Term</strong></small></label
      >
      <div class="accordion accordion-flush">
        <div
          v-for="(item, index) in filterYearTerm"
          class="accordion-item"
          v-bind:key="index"
        >
          <button
            class="accordion-button collapsed px-0 py-2"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="'#' + index"
            aria-expanded="false"
          >
            <small>{{ item.year }}</small>
          </button>
          <div :id="index" class="accordion-collapse collapse">
            <div class="accordion-body">
              <div
                class="form-check"
                v-for="(subItem, subIndex) in item.term"
                v-bind:key="subIndex"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  name="term"
                  :id="subItem + item.year"
                />
                <label class="form-check-label" :for="subItem + item.year">
                  <small>{{ subItem + item.year }} </small>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const filterYearTerm = [
  {
    year: 2023,
    term: ["BVH", "BVM", "BVU", "HVM", "HVZ"],
  },
  {
    year: 2024,
    term: ["BVH", "BVM", "BVU", "HVM", "HVZ"],
  },
  {
    year: 2025,
    term: ["BVH", "BVM", "BVU", "HVM", "HVZ"],
  },
];
</script>
